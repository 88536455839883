$bu: gss;
@import '../../custom';

.specifications-to-qualification-content {
  padding-top: 70px;
  .header {
    font-size: $font-large;
  }

  .sub-header {
    margin-top: 0.25rem;
  }

  .body {
    width: 100%;
    margin-top: 60px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto;
    column-count: 2;

    .specifications-for-letter {
      padding-bottom: 40px;
      width: 50%;

      .specifications-letter {
        font-size: $font-large;
        color: $primary;
        padding-bottom: 6px;
        height: 31px;
      }

      .specification-name {
        font-size: $font-medium;
        padding-top: 16px;
        height: 43px;
        cursor: pointer;
      }
    }
  }
}
