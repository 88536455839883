$bu: gss;
@import '../../../../custom';

.pathway-selector-dropdown {
  & a.nav-link {
    margin: 0;
    padding: 0;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: max-content;
    width: 100%;
    padding: 0.5rem 1rem;

    .col-md-4 {
      min-width: 2rem;
    }

    .col-md-20 {
      display: flex;
      justify-content: flex-end;
    }
  }

  .dropdown-menu {
    .selected-pathway {
      background-color: $primary;
    }
  }

  .pathways-text,
  .pathways-unselected-text {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.25rem !important;
  }

  .pathways-text {
    color: $primary;
  }
  .pathways-unselected-text {
    color: $black;
  }

  .patways-icon-container {
    display: grid;
    place-items: center;
    padding: 0;

    .pathways-icon {
      margin: 0.25rem;
    }
  }

  .patways-selection-container {
    display: grid;
    align-items: center;
    padding-right: 0;
  }
}

.spinner {
  color: $primary !important;
}

.pathways-unavailable {
  display: flex;
  justify-content: flex-end;
}
